@import '../../../scss/variables';
@import '../../../scss/mixins/index';

.container {
  @extend %flexbox;
  @include flex-dir(column);
  flex: 1 1;
  box-sizing: border-box;
  margin-top: $mp-xl;
}

.content {
  padding: 0 $mp-ml;
}

.unlock {
  @extend %flexbox;
  @include align-items(center);
  @include flex-just(center);
  background: $background-light;
  border-top: $border-s solid $text-primary;
  padding: $mp-ml;
  position: fixed;
  height: 1.57rem;
  width: 100vw;
  top: calc(100vh - 3.92rem);

  > div {
    flex: 1 0 0;
  }

  > span {
    flex: 1.5 0 0;
  }

  &.disabled {
    color: $text-inactive;
    cursor: default;

    svg {
      fill: $text-inactive;
    }
  }
}
