@import '../../../../scss/variables';
@import '../../../../scss/mixins/index';

.archivedContentContainer {
  border: $border-s solid $color-blue;
  border-radius: $mp-xxxs;
  padding: $mp-s 0;
  margin-bottom: $mp-xl;
}

.capitalize {
  text-transform: capitalize;
}

.contentRowOverride {
  @extend %flexbox;
  @include flex-just(space-between);
  flex-basis: 2.85rem;
  padding: 0.14rem $mp-sm 0.14rem 0;

  > span:last-child {
    margin-left: $mp-xs;
  }
}

.iconAndTitle {
  @extend %flexbox;
  padding: $mp-s 0 $mp-s $mp-sm;

  > span {
    margin-left: $mp-l;
  }
}

.ieWrapper {
  @extend %flexbox;
  @include flex-dir(column);
  flex-basis: 2.85rem;
}

.keywordStyle {
  background: $color-blue;
  padding: $mp-xxs $mp-xs;
  color: $color-light-blue;
  border-radius: $mp-xxxs;
  @extend %font-default-light;
  text-transform: capitalize;
}
.keywordsList {
  @extend %flexbox;
  flex-wrap: wrap;
  list-style-type: none;
  padding: $mp-xxxs 0.14rem 0.14rem 0.14rem;
  @include flex-just(left);

  > li {
    margin: 0.14rem;
  }
}
