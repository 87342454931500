@import 'variables.scss';
@import './mixins/index.scss';
@import "../../node_modules/its-react-ui/dist/its-react-ui.css";

::-webkit-scrollbar {
  display: none;
}

html, body {
  position: relative;
  background: $background-primary;
  @extend %flexbox;
  color: $text-primary;
  font-family: Calibri;
  font-size: $font-size-base;
  margin: 0;
  -ms-overflow-style: none;
  overflow: auto;
  overflow-x: hidden;
}

body {
  @include flex-dir(column);
  min-height: 100vh;
  width: 100%;
}

#root {
  @extend %flexbox;
  flex-grow: 1;
  @include flex-dir(column);
  @include flex-just(center);
}

.bold {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  white-space: nowrap;

  &.bold {
    font-weight: bold;
  }
}

h1 {
  font-size: $font-size-heading-large;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

h3 {
  margin: 0;
  font-size: $font-size-heading-small;
  margin-bottom: $mp-ml;
}

h4 {
  margin: $mp-s 0;
  font-size: $font-size-heading-small;
  font-weight: normal;
  white-space: normal;
}

h6 {
  margin: 0;
  font-weight: normal;
  font-size: $font-size-base;
}

a {
  color: $text-primary;
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

input {
  color: $text-primary;
}

input::-ms-clear {
  display: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}
