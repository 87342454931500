@import '../scss/mixins/index.scss';
@import '../scss/variables.scss';

.container {
  @extend %flexbox;
  @include align-items(center);
  border: $border-s solid $border-primary;
  border-radius: $mp-xxxs;
  padding: $mp-sm;
  margin: 0 $mp-ml;

  p {
    margin: 0 0 0 $mp-sm;
    font-size: $font-size-default;
  }
}