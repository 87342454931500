@import '../../scss/variables.scss';
@import '../../scss/mixins/index.scss';

.container {
  @extend %flexbox;
  @include flex-dir(column);
  @include align-items(center);

  > h1 {
    margin-bottom: 0;
  }

  > h4 {
    text-align: center;
    width: 90vw;
  }
}
