@import '../../../scss/variables';
@import '../../../scss/mixins/index';

.container {
  display: flex;
  flex-direction: column;
  border-bottom: $border-s solid $color-light-blue;
  padding: $mp-s $mp-ml $mp-s $mp-s;

  > div {
    display: flex;
  }

  background: none;
}

.addNoteButton {
  color: $text-primary;
  font-weight: bold;
  padding: 0;
}

.iconAndTitle {
  display: flex;

  flex: 1 1;

  > span, > input {
    margin-left: $mp-xl;
  }
}

.note {
  font-size: $font-size-small;
  margin: $mp-m $mp-m $mp-xxxs 0.14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
