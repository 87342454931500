@import '../../scss/variables.scss';
@import '../../scss/mixins/index.scss';

.titleContainer {
  @extend %flexbox;
}

.title {
  margin-left: $mp-ml;
  margin-top: $mp-xxxs;
  white-space: normal;
}
