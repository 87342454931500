@import '../scss/variables.scss';

.linkButton {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1.5px dotted $text-primary;
  cursor: pointer;
  color: $text-primary;
}
