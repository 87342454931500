@import '../../../../scss/variables';
@import '../../../../scss/mixins/index';

.container {
  margin-top: $mp-sm;

  > div:first-child {
    border-top-left-radius: $mp-xxxs;
    border-top-right-radius: $mp-xxxs;
  }

  > div:last-child {
    border-bottom-left-radius: $mp-xxxs;
    border-bottom-right-radius: $mp-xxxs;
  }
}

.contextDropDown {
  z-index: 1;
}
