@import '../../../../scss/variables';
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/cross-flex';

.container {
  @extend %flexbox;
  position: fixed;
  top: calc(100vh - 3.9rem);
  width: 100%;
}

.archiveButton {
  @extend %flexbox;
  @include flex-just(center);
  @include align-items(center);
  flex: 1 1 0;
  background: $background-secondary;
  cursor: pointer;
  border: none;
  height: 3.9rem;
}

.content {
  display: flex;
  align-items: center;
  padding: $mp-sm 0;

  > span {
    @extend %font-default;
    margin-left: $mp-ml;
    color: $color-white;
  }
}

.cancelOrUpdateOrArchive {
  display: flex;
  flex-direction: column;
  width: 100%
}
.spinner, .exclamation {
  position: absolute;
  right: 1rem;
  > svg {
    height: 2rem;
    width: 2rem;
    > g {
      > path {
        stroke: white;
      }
    }
  }
}

.exclamation {
  > svg {
    > g {
      > path {
        stroke: $color-red;;
      }
    }
  }
}

.buttonRow {
  @extend %flexbox;
  @include flex-just(space-between);
  @extend %font-default-light;
  background: $background-secondary;
  padding: 0.4rem 1.4rem;

  a, button, #linkButton {
    color: $text-secondary;
    flex-direction: column;
    > svg {
      fill: $text-secondary;
    }
  }

  button:disabled, button.isDisabled {
    cursor: default;
    color: $text-inactive;
    > svg {
      fill: $text-inactive;
    }
  }

  > div {
    width: 0.07rem;
    background: $background-primary;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
}
