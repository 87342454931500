/* Base colors */
$color-blue: #003c71;
$color-maritime-blue: #1a95e7;
$color-maritime-blue-dark: #769ab8;
$color-light-blue: #d9e3ec;
$color-very-light-blue: #f0f4f7;
$color-green: #1ee5a0;
$color-dark-green: #6ecb98;
$color-light-green: rgba(30, 229, 160, 0.3);
$color-red: #f5866c;
$color-light-red: #fac2b5;
$color-dark-red: #880d18;
$color-light-grey: #eaedef;
$color-grey: #929aa0;
$color-white: white;
$color-dark: #001a32;

/* Text colors */
$text-primary: $color-blue;
$text-secondary: $color-white;
$text-maritime-light: $color-maritime-blue-dark;
$text-inactive: $color-grey;
$text-error: $color-dark-red;

/* UI element colors */
$background-primary: $color-light-blue;
$background-secondary: $color-blue;
$background-context: $color-dark-green;
$background-light: $color-white;
$background-light-blue: $color-very-light-blue;
$background-error: $color-light-red;
$border-primary: $color-blue;
$border-grey: #a5b7c4;
$border-error: $color-dark-red;

/* Base font evaluates to 14px on default width 320px. Use as starting point for scaling */
$font-size-base: 4.38vw;
$font-size-base-px: 14;

/* Font sizes */
$font-size-default: 1rem; // 14px
$font-size-small: 0.86rem; // 12px
$font-size-heading-small: 1.14rem; // 16px
$font-size-heading: 1.28rem; // 18px;
$font-size-heading-large: 1.57rem; // 22px

/* Margin & padding sizes */
$mp-xxxs: 0.28rem; // 4px
$mp-xxs: 0.42rem; // 6px
$mp-xs: 0.57rem; // 8px
$mp-s: 0.71rem; // 10px
$mp-sm: 0.86rem; // 12px
$mp-m: 1rem; // 14px
$mp-ml: 1.14rem; // 16px
$mp-l: 1.28rem; // 18px
$mp-xl: 1.42rem; // 20px
$mp-xxl: 1.57rem; // 22px
$mp-xxxl: 2rem; // 28px

/* Border sizes */
$border-s: 0.07rem;
$border-l: 0.14rem;

/* Icon sizes */
$icon-size-s: 1.28rem; // 18px
$icon-size-m: 1.57rem; // 22px
$icon-size-l: 1.71rem; // 24px
$icon-size-xl: 4.5rem; // 63px

:export { iconSizeSmall: $icon-size-s; }
:export { iconSizeMedium: $icon-size-m; }
:export { iconSizeLarge: $icon-size-l; }
:export { iconSizeExtraLarge: $icon-size-xl; }

:export { fontSizeBasePx: $font-size-base-px; }
