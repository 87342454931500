@import '../variables.scss';

@mixin fontDefault {
  font-family: Calibri;
  font-size: $font-size-default;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

@mixin fontDefaultLight {
  font-family: 'Calibri Light';
  font-size: $font-size-default;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

@mixin fontHeadingLarge {
  font-size: $font-size-heading-large;
  font-family: Calibri;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

%font-default { @include fontDefault; }
%font-default-light { @include fontDefaultLight; }
%font-heading-large { @include fontHeadingLarge; }
