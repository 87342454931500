@import '../../../scss/variables';
@import '../../../scss/mixins/index';

.container {
  flex: 1 1;
  background: $background-error;
  @extend %flexbox;
  @include flex-dir(column);
  @include flex-just(center);

  h1, h4 {
    color: $color-dark-red;
    white-space: normal;
    text-align: center;
  }
}

.content {
  flex: 1 1;
  @extend %flexbox;
  @include align-items(center);
  @include flex-dir(column);
  @include flex-just(center);

  > h4 {
    text-align: center;
    width: 90vw;
    white-space: normal;
  }
}

.dismissButton {
  width: 100vw;
  font-size: $font-size-heading;
  color: $color-red;
  text-align: center;
  padding: $mp-xxl 0;
  background: $color-dark-red;
}
