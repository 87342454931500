@import '../../../scss/variables';
@import '../../../scss/mixins/index';

.container {
  @extend %flexbox;
  flex-grow: 1;
  @include flex-dir(column);
  @include flex-just(space-between);
  margin-top: $mp-xl;
}

.marginForFixedArchiveBar {
  margin-bottom: 12vh;
}

.content {
  padding: 0 $mp-m 5rem $mp-m;
  box-sizing: border-box;;
}

.fixedBar {
  position: fixed;
  bottom: 3.6rem;
  width: 100vw;
}

.archiveMargin {
  margin-top: $mp-xxl;
  position: fixed;
  bottom: 3.6rem;
  width: 100vw;
}
