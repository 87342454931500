@import '../../scss/variables.scss';
@import '../../scss/mixins/index.scss';

.container {
  @extend %flexbox;
  @include flex-just(space-between);
  @include align-items(center);
  padding: 0 $mp-m;
  color: $text-secondary;
  background: $color-dark;
}

.dots {
  fill: $text-secondary;
  cursor: pointer;
  margin-top: 0.14rem;
}
