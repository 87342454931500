@import '../../scss/variables.scss';
@import '../../scss/mixins/cross-flex.scss';

.container {
  margin-top: $mp-xl;

  > div:not(:last-child) {
    margin-bottom: 0.14rem;
  }
}

.error {
  @extend %flexbox;
  @include align-items(center);
  background: $color-red;
  color: $color-white;
  font-weight: bold;
  padding: $mp-xxxs 0 $mp-xxs $mp-xxxl;

  svg {
    margin-right: $mp-xl;
    fill: $color-white;
  }
}
