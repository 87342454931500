@import '../../../scss/variables';

.icon {
  align-self: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.buttonFlex {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  button {
    height: 2.285rem;
    border-radius: 0.285rem;
    border: 1px solid;
  }
}

.cancel {
  width: 8rem;
  border-color: $color-blue;
  color: $text-primary;
}

.close {
  width: 100%;
  background: $color-blue;
  color: #fff;
}

.doUnarchive {
  width: 8rem;
  background: $border-error;
  color: #fff;
}
