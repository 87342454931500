@import '../../scss/variables';
@import '../../scss/mixins/index';

.container {
  width: 100vw;
  height: 100vh;
  @extend %flexbox;
  @include align-items(center);
  @include flex-just(center);
  @include flex-dir(column);
}
