@import '../scss/mixins/index.scss';
@import '../scss/variables.scss';

.container {
  position: absolute;
  right: 2%;
  margin-top: $mp-xs;
  list-style-type: none;
  background: white;
  white-space: nowrap;
  z-index: 1;
  box-shadow: 0 $mp-xxs $mp-s 0 rgba(0, 60, 113, 0.5);
  border-radius: $mp-xxxs;
  padding: 0 $mp-xxxs;
  text-align: left;
  display: none;
  &.visible {
    display: block;
  }
  &.bringForward {
    z-index: 1;
  }
  li:first-child:not(.warning) {
    margin-top: $mp-sm;
  }

  li:last-child {
    margin-bottom: $mp-sm;
  }
}

.checkbox {
  fill: $color-blue;

  &.unchecked {
    opacity: 0.6;
  }
}

.warning {
  background: $color-red;
  color: $text-secondary;
  white-space: normal;
  border-radius: $mp-xxxs;
  padding: $mp-xxs;
  margin: $mp-xxxs 0 $mp-xxs 0;
}

.item {
  @extend %flexbox;
  @include align-items(center);

  cursor: pointer;
  font-weight: normal;
  padding: $mp-xxs 3.14rem $mp-xxs $mp-sm;
  margin-bottom: $mp-s;
  color: $color-blue;
  border-radius: $mp-xxxs;

  span {
    margin-left: $mp-ml;
  }

  &:hover {
    background: $color-light-green;
  }
}
